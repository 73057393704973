function SocialLink({ href, icon, title }) {
  return (
    <a
      href={href}
      aria-label={`Show ${title}` || "Social Link"}
      target="_blank"
      rel="noreferrer"
      className="social__link click"
    >
      {icon}
    </a>
  );
}

export default SocialLink;
