import { FaGithub, FaLink } from "react-icons/fa";
import Skeleton from "./Skeleton";
import { useState } from "react";

function Project({ title, tools, para, img, github, link }) {
  const [loading, setLoading] = useState(true);

  return (
    <>
      <li className="project">
        <div className="project__info">
          {loading ? (
            <>
              <Skeleton
                borderRadius={4}
                height={48}
                width={400}
                classN="project__title"
              />
              <Skeleton
                classN="project__para"
                borderRadius={4}
                height={64}
                width={600}
              />
              <Skeleton height={17} width={180} classN="project__tools" />
              <div className="project__links">
                <Skeleton
                  borderRadius={100}
                  width={36}
                  height={36}
                  classN="project__description--link link__skeleton"
                ></Skeleton>
                <Skeleton
                  borderRadius={100}
                  width={36}
                  height={36}
                  classN="project__description--link link__skeleton"
                ></Skeleton>
              </div>
            </>
          ) : (
            <>
              <h1 className="project__title">{title}</h1>
              <p className="project__para">{para}</p>
              <h2 className="project__tools">{tools}</h2>
              <div className="project__links">
                <a
                  href={github}
                  target="_blank"
                  rel="noreferrer"
                  className="project__description--link"
                  aria-label="Projects Source Code"
                  >
                  <FaGithub className="fas fa-github" />
                </a>
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  className="project__description--link"
                  aria-label="Live Demo"
                >
                  <FaLink className="fas fa-link" />
                </a>
              </div>
            </>
          )}
        </div>
        <div className="project__right">
          <figure className="project__img--wrapper">
            {loading && <Skeleton img={true} classN="project__img" />}
            <img
              onLoad={() => setLoading(false)}
              src={img}
              alt=""
              style={{
                visibility: `${loading ? "hidden" : "visible"}`,
              }}
              className="project__img"
            />
          </figure>
        </div>
      </li>
    </>
  );
}

export default Project;
