import React, { lazy, useState } from "react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import "./App.css";
import { MoveBackground, handeLoad } from "./lib/DomHandler.jsx";
import Nav from "./components/Nav.jsx";
import Landing from "./pages/Landing.jsx";
const BackgroundIcons = lazy(() =>
  import("./components/UI/BackgroundIcons.jsx")
);
const Footer = lazy(() => import("./components/Footer.jsx"));
const Modal = lazy(() => import("./components/UI/Modal.jsx"));

function App() {
  const [ModelOpen, setModelOpen] = useState(false);

  function toggleModal() {
    if (ModelOpen) {
      setModelOpen(false);
      return document.body.classList.remove("modal--open");
    }
    setModelOpen(true);
    document.body.classList += " modal--open";
    document.documentElement.scrollTop = 0;
  }

  return (
    <div
      onLoad={handeLoad}
      className="App"
      onMouseMove={(event) => MoveBackground(event)}
    >
      <BackgroundIcons></BackgroundIcons>
      <Nav toggleModal={toggleModal} />
      <Modal toggleModal={toggleModal} />
      <Landing toggleModal={toggleModal} />
      <Footer toggleModal={toggleModal} />
      <SpeedInsights />
    </div>
  );
}

export default App;
